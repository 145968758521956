import React from 'react';
import { __DEVELOPER_APP_VERSION__ } from '@/types/constants';
import FacebookIcon from './svg/social/FacebookIcon';
import YoutubeIcon from './svg/social/YoutubeIcon';
import WebSiteIcon from './svg/social/WebSiteIcon';

const profiles = {
	website: "https://epraja.com.br/",
	youtube: "https://www.youtube.com/c/EPJImpress%C3%B5esInteligentes",
	facebook: "https://web.facebook.com/epjimpressoesinteligentes",
	instagram: "https://www.instagram.com/epjimpressoesinteligentes/",
};

const socialMediaIcons: any = {
	website: <WebSiteIcon />,
	// facebook: <FacebookIcon />,
	youtube: <YoutubeIcon />,
	// instagram: InstagramIcon,
};

interface FooterInterface {
}

const Footer: React.FC<FooterInterface> = () => {
	return (
		<footer className={`flex h-12 items-center bg-blue-500 w-full fixed bottom-0 left-0 px-8 text-center text-white shadow-lg`}>
			<small className='w-1/3'>&copy; Copyright 2023, Todos os Direitos Reservados</small>
			<SocialMediasContainer profiles={profiles} />
			<small className='w-1/3 flex justify-end'>v{__DEVELOPER_APP_VERSION__}</small>
		</footer>
	)
}

export default Footer;

const SocialMediasContainer: React.FC<{ profiles: any }> = ({ profiles }) => {
	return (
		<div className="flex justify-center items-center space-x-4 w-full h-full">
			{Object.keys(profiles).map((platform) => (
				<a
					key={platform}
					href={profiles[platform]}
					target="_blank"
					rel="noopener noreferrer"
				>
					{
						socialMediaIcons[platform]
					}
				</a>
			))}
		</div>
	);
};
