type MailOptions = {
	to: string,
	subject: string,
	html: string
}

export default class EmailSender {
	static async sendEmail(mailOptions: MailOptions): Promise<void> {
		await fetch('/api/send-email', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(mailOptions),
		});
	}

	static generateHTMLEmailContent(textContent: string): string
	{
		const emailStyles = `
			<style>
				body {
					font-family: Arial, sans-serif;
					background-color: #f2f2f2;
					padding: 20px;
				}
				header {
					background-color: #ff704d;
					color: #ffffff;
					text-align: center;
					padding: 10px;
				}
				.content {
					background-color: #ffffff;
					padding: 20px;
					border-radius: 5px;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
					display: flex;
					justify-content: center;
					align-text: center;
					width: 100%;
				}
				footer {
					background-color: #ff704d;
					color: #ffffff;
					text-align: center;
					padding: 10px;
				}
			</style>
		`;

		const emailContent = `
			<html>
				<head>
					${emailStyles}
				</head>
				<body>
					<header>
						<h1>Bem-vindo ao SalesTAG</h1>
					</header>
					<div class="content">
						${textContent}
					</div>
					<footer>
						<p>© Copyright 2023, Todos os Direitos Reservados</p>
					</footer>
				</body>
			</html>
		`;
		return emailContent;
	}
}
