import React, { useEffect, useState } from 'react';
import { useMember } from '@/contexts/member/MemberContext';
import LoginModal from '@/components/modals/login/LoginModal';
import FetchingMemberModal from '@/components/modals/FetchingMemberModal';
import IncompleteUserDataModal from '@/components/modals/warnings/IncompleteUserDataModal';
import LandingPage from '@/components/grids/home/LandingPage';
import { useModals } from '@/contexts/modals/ModalsContext';
import OnboardingModal from '@/components/modals/onboarding/OnboardingModal';
import ProfilePictureSelector from '@/components/modals/common/ProfilePictureSelector';
import LicenseModal from '@/components/modals/common/LicenseModal';
import Dashboard from './dashboard';
import RegistrationModal from '@/components/modals/login/RegistrationModal';
import DeveloperModal from '@/components/modals/common/DeveloperModal';

const Home = () => {
    const {
        member,
        isLoginModalVisible,
        setIsLoginModalVisible,
        isRegistrationModalVisible,
        setIsRegistrationModalVisible
    } = useMember();
    const [isAllowed, setIsAllowed] = useState(false);

    return (
        <div className='w-screen flex flex-col justify-center items-center' >
            <React.Fragment >
                {
                    member.isLogged() ?
                        <MainPage />
                        : <LandingPage />
                }
                <LoginModal isOpen={isLoginModalVisible} onClose={() => setIsLoginModalVisible(false)} />
                <RegistrationModal isOpen={isRegistrationModalVisible} onClose={() => setIsRegistrationModalVisible(false)} />
                <FetchingMemberModal />
                <IncompleteUserDataModal isOpen={isAllowed} closeModal={() => setIsAllowed(false)} />
            </React.Fragment>
        </div>
    );
}

export default Home;

const MainPage: React.FC = () => {
    return (
        <>
            <Dashboard />
            <MainModals />
        </>
    );
}

const MainModals: React.FC = React.memo(() => {
    const { member } = useMember();
    const { showOnboarding, setShowOnboarding, isProfilePictureSelectorVisible, setProfilePictureSelectorVisibility } = useModals();

    useEffect(() => {
        if (member.isLogged() && !member.finishOnboarding) {
            setShowOnboarding(true);
        }
    }, [member]);

    return (
        <>
            <LicenseModal />
            <OnboardingModal isOpen={showOnboarding} onClose={() => setShowOnboarding(false)} />
            <ProfilePictureSelector isOpen={isProfilePictureSelectorVisible} onClose={() => setProfilePictureSelectorVisibility(false)} />
            <DeveloperModal />
        </>
    )
});
