import React from 'react';
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import Choose from '@public/animations/choose.json';
import Gears from '@public/animations/gears.json';
import Printer from '@public/animations/custom-printer.json';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

const LandingPage: React.FC = () => {
	return (
		<div className="w-screen flex flex-col items-center justify-center min-h-screen overflow-auto pt-5 pb-32 lg:pb-52 bg-slate-50">
			<Header />
			<header className="max-w-2xl text-center p-2 xl:mt-[1rem] lg:mt-[1rem] md:mt-[1rem] sm:mt-[40rem] xs:mt-[45rem] xs:mb-20">
				<h2 className="text-3xl font-bold text-orange-500 uppercase">Veja como é fácil precificar o seu PDV usando nosso Sistema!</h2>
			</header>

			<main className="max-w-8xl grid grid-cols-1 md:grid-cols-3 gap-10 px-10">
				<Section index={1} header='Escolha o Modelo do Cartaz' content='Selecione um, entre os diversos modelos.' animation={Choose} />
				
				<Section index={2} header='Escolha os Produtos' content='Adicione seus produtos no modelo escolhido.' animation={Gears} />
				
				<Section index={3} header='Pronto!' content='Seu arquivo está pronto para ser impresso.' animation={Printer} />
			</main>

			<Footer />
        </div>
    );
}

export default LandingPage;

interface SectionInterface {
	index: number,
	header: string,
	content: string,
	animation: object
}

const Section: React.FC<SectionInterface> = ({ index, header, content, animation }) => {
	return (
		<section className="relative card bg-white rounded-lg p-5 shadow-lg flex flex-col h-full">
			<div className="absolute top-0 left-0 transform -translate-y-1/2 -translate-x-1/2 bg-orange-500 text-white rounded-full h-12 w-12 flex items-center justify-center">
				<span className="text-xl font-bold">{index}</span>
			</div>
			<h2 className="text-2xl text-center font-semibold uppercase text-orange-500 mb-2">
				{header}
			</h2>
			<div className="flex-grow flex justify-center items-center">
				<Lottie play animationData={animation} loop style={{ height: 200 }} />
			</div>
			<p className="text-gray-800 text-center flex-grow">
				{content}
			</p>
		</section>
	)
}
