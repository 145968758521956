import React, { useEffect, useState } from "react";
import HandleModal from "@/components/modals/BlurModal";
import { useModals } from "@/contexts/modals/ModalsContext";
import DEBUG from "@/helpers/DEBUG";
import Api from "@/api/Api";
import { useMember } from "@/contexts/member/MemberContext";

interface InputProps {
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
}

const Input: React.FC<InputProps> = ({ placeholder, value, onChange }) => (
    <input className="border p-2 m-2 w-full" placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
);

interface ButtonProps {
    children: React.ReactNode;
    onClick: () => void;
    className?: string;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className = 'bg-blue-500' }) => (
    <button className={`text-white p-2 m-2 ${className}`} onClick={onClick}>{children}</button>
);

interface KeyValuePairListProps {
    keyValuePairs: { [key: string]: string };
}

const KeyValuePairList: React.FC<KeyValuePairListProps> = ({ keyValuePairs }) => (
    <div className="flex">
        <div className="m-2 p-2 border w-1/2">
            <ul>
                {Object.entries(keyValuePairs).map(([k, v]) => (
                    <li key={k}>{`${k}: ${v}`}</li>
                ))}
            </ul>
        </div>
        <div className="m-2 p-2 border w-1/2">
            <pre>{JSON.stringify(keyValuePairs, null, 2)}</pre>
        </div>
    </div>
);

const DeveloperModal = () => {
    const { showDeveloperModal, setShowDeveloperModal } = useModals();
    const { member } = useMember();

    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [keyValuePairs, setKeyValuePairs] = useState({});
    const [result, setResult] = useState("");
    const [insertHash, setInsertHash] = useState(false);

    const resetState = () => {
        setKey("");
        setValue("");
        setKeyValuePairs({});
        setResult("");
    };

    const addKeyValuePair = () => {
        setKeyValuePairs({ ...keyValuePairs, [key]: value });
        setKey("");
        setValue("");
    };

    const getKeyValuePairs = () => {
        const pairs = { ...keyValuePairs };
        // @ts-ignore
        if (insertHash) pairs['hash'] = member.hash;
        return pairs;
    }

    const generateJson = () => {
        const jsonResult = JSON.stringify(getKeyValuePairs(), null, 2);
        setResult(jsonResult);
    };

    const encryptJson = () => {
        const encrypted = Api.encryptRequest(getKeyValuePairs());
        const formattedEncrypted = JSON.stringify(encrypted, null, 2);
        setResult(formattedEncrypted);
    };

    useEffect(() => {
        if (!showDeveloperModal) {
            resetState();
        }
    }, [showDeveloperModal]);

    return (
        <HandleModal isOpen={showDeveloperModal} onClose={() => setShowDeveloperModal(false)}>
            <div className="flex flex-col pt-4 min-w-[35rem] max-h-[40rem] overflow-auto">
                <div className="flex flex-row w-full justify-between">
                    <Input placeholder="Key" value={key} onChange={setKey} />
                    <Input placeholder="Value" value={value} onChange={setValue} />
                    <Button onClick={addKeyValuePair}>Add</Button>
                </div>
                <KeyValuePairList keyValuePairs={keyValuePairs} />
                <div className="p-2 m-2">
                    <label>
                        <input type="checkbox" checked={insertHash} onChange={() => setInsertHash(!insertHash)} />
                        {" "}Inserir hash
                    </label>
                </div>
                <Button onClick={generateJson} className='bg-green-500'>Generate JSON</Button>
                <Button onClick={encryptJson} className='bg-orange-500'>Encrypt JSON</Button>
                <div className="my-4 p-2 border w-full">
                    <textarea className="w-full h-[8rem]" readOnly value={result} />
                </div>
            </div>
        </HandleModal>
    );
};

export default DeveloperModal;
