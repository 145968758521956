import React from "react";
import HandleModal from "@/components/modals/BlurModal";
import { ReactChildren } from "@/types";
import { useMember } from "@/contexts/member/MemberContext";

interface IncompleteUserDataModalInterface {
	isOpen: boolean;
	closeModal: () => void;
}

interface RowInterface {
	children: ReactChildren
}

const IncompleteUserDataModal: React.FC<IncompleteUserDataModalInterface> = ({ isOpen, closeModal }) => {
	const { member } = useMember();

	const onClose = () => {
		member.logout();
		closeModal();
	}

	return (
		<HandleModal isOpen={isOpen} onClose={() => null} closable={false}>
			<div className="flex flex-col justify-center items-center text-center w-[500px] no-text-highlight">
				<span className="cursor-default no-text-highlight text-2xl mt-3 mb-4" >
					Não Autorizado
				</span>
				<Row>
					<span className="w-2/5" >
						Você não está autorizado a continuar
					</span>
				</Row>
				<Row>
					<button className="rounded-lg px-3 py-2 bg-orange-200 hover:bg-orange-300 duration-300" onClick={onClose} >
						Voltar
					</button>
				</Row>
			</div>
		</HandleModal>
	)
}

const Row: React.FC<RowInterface> = ({ children }) => {
	return (
		<div className="flex flex-row w-full justify-center items-center mt-4">
			{children}
		</div>
	)
}

export default IncompleteUserDataModal;
